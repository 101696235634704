// Bootstrap settings

$primary: #1e0bf7;
$enable-shadows: true;
//$prefix: "mo-";

@import "node_modules/bootstrap/scss/bootstrap";

// fonts
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700&display=swap');


body{
    font-family: 'Mulish', sans-serif;
}
img{
    max-width: 100%;
    height: auto;
}
.codehints-video{
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 30px;

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}